<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  position: relative;
}

.install-button {
  padding: 8px 16px;
  background-color: #e8b751;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  position: absolute;
  bottom: 20px; /* Positioned at the bottom with an offset */
  left: 50%;
  transform: translateX(-50%);
  z-index: 10; /* Ensure it's above other content */
}

.install-button:hover {
  background-color: #CF9E38;
}
</style>
