const VERSION = '1.0.0'; // replace '1.0.0' with the actual version

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './global.css';
import 'mdb-vue-ui-kit/css/mdb.min.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCalendarAlt, faLocationDot, faCircleInfo, faHotel, faCamera, faShield, faCar } from '@fortawesome/free-solid-svg-icons';
import { getMessaging, onMessage, getToken } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';

// Add the imported icons to the library
library.add(faCalendarAlt, faLocationDot, faCircleInfo, faHotel, faCamera, faShield, faCar);

// Initialize Firebase
const firebaseApp = initializeApp({
  apiKey: "AIzaSyC-Xm0cPel2yqXyQ8aAg2Okx4LyLn0U2XY",
  authDomain: "specmasters-app-2024.firebaseapp.com",
  projectId: "specmasters-app-2024",
  storageBucket: "specmasters-app-2024.appspot.com",
  messagingSenderId: "590466301838",
  appId: "1:590466301838:web:4c790b76da1b31838d2edf",
  measurementId: "G-1PJ48ZLVYP"
});

const messaging = getMessaging(firebaseApp);

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    // Register the Firebase Messaging service worker
    navigator.serviceWorker.register('/firebase-messaging-sw.js').then(initialRegistration => {
      console.log('Firebase Service Worker registration successful with scope: ', initialRegistration.scope);

      // Existing Service Worker for your app
      navigator.serviceWorker.register('/service-worker.js').then(registration => {
        console.log('App ServiceWorker registration successful with scope: ', registration.scope);

        // Check for updates
        registration.update();

        // Listen for updates found
        registration.addEventListener('updatefound', () => {
          const newWorker = registration.installing;
          newWorker.addEventListener('statechange', () => {
            if (newWorker.state === 'installed' && navigator.serviceWorker.controller) {
              // Check the version of the new worker
              newWorker.postMessage({ type: 'CHECK_VERSION' });
              newWorker.addEventListener('message', (event) => {
                if (event.data.type === 'VERSION' && event.data.version !== VERSION) {
                  // Prompt user to update
                  console.log('New content is available; please refresh.');
                  if (confirm('A new version is available. Reload to update?')) {
                    window.location.reload();
                  }
                }
              });
            }
          });
        });
      }, err => {
        console.log('App ServiceWorker registration failed: ', err);
      });

      // Request permission and get token for Firebase Messaging
      function requestPermission() {
        console.log('Requesting permission...');
        Notification.requestPermission().then((permission) => {
          if (permission === 'granted') {
            console.log('Notification permission granted.');

            // Get registration token. Initially this makes a network call, once retrieved
            // subsequent calls to getToken will return from cache.
            getToken(messaging, { vapidKey: 'BBr83xqGX1VsDELQtr5oT9OXEjAI7913kftCeEQiq5IILycjvSz5_Q_j1-dVXy-E3VD6QJaeMUn-QFUl_YGVu3M' }).then((currentToken) => {
              if (currentToken) {
                // Send the token to your server and update the UI if necessary
                // ...
              } else {
                // Show permission request UI
                console.log('No registration token available. Request permission to generate one.');
                // ...
              }
            }).catch((err) => {
              console.log('An error occurred while retrieving token. ', err);
              // ...
            });

            // Handle foreground messages
            onMessage(messaging, (payload) => {
              console.log('Message received. ', payload);
              // Customize notification here
              if (Notification.permission === 'granted' && payload.notification) {
                new Notification(payload.notification.title, { body: payload.notification.body });
              }
            });
          }
        });
      }

      // Call the requestPermission function
      requestPermission();

    }); // This is the missing closing bracket
  }); // This is the added closing bracket
}

// Create the Vue application and register FontAwesomeIcon globally
createApp(App)
  .component('font-awesome-icon', FontAwesomeIcon)
  .use(router)
  .mount('#app');