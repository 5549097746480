import { createRouter, createWebHistory } from 'vue-router'

// Use dynamic imports for your components
const HomePage = () => import('./components/HomePage.vue')
const AgendaPage = () => import('./components/AgendaPage.vue')
const LocalGuide = () => import('./components/LocalGuide.vue')
const HotelPage = () => import('./components/HotelPage.vue')
const PhotoSharing = () => import('./components/PhotoSharing.vue')
const FAQ = () => import('./components/FAQ.vue')
const Team = () => import('./components/Team.vue')

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/AgendaPage',
    name: 'AgendaPage',
    component: AgendaPage
  },
  {
    path: '/LocalGuide',
    name: 'LocalGuide',
    component: LocalGuide
  },
  {
    path: '/HotelPage',
    name: 'HotelPage',
    component: HotelPage
  },
  {
    path: '/PhotoSharing',
    name: 'PhotoSharing',
    component: PhotoSharing
  },
  {
    path: '/FAQ',
    name: 'FAQ',
    component: FAQ
  },
  {
    path: '/Team',
    name: 'Team',
    component: Team
  },
  // You can define additional routes here
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
